<template>
  <div class="homePage">
    <div class="header" id="intro">
      <img class="banner" src="../assets/banner.jpg" />
      <div class="left-text">
        <div class="line1">
          <span class="text1">KuaiCDN</span>
          <span class="text2">{{ $t("word1") }}</span>
        </div>
        <div class="line2">{{ $t("word2") }}</div>
        <div class="line3">
          <img src="../assets/banner_line.png" />
        </div>
        <div class="line4">{{ $t("word3") }}</div>
        <div class="line5">{{ $t("word4") }}</div>
        <div class="line6">{{ $t("word5") }}</div>
        <div class="line7">{{ $t("word5_1") }}</div>
      </div>
    </div>

    <div class="part1" id="feature">
      <div class="title">
        <span class="text1">KuaiCDN&nbsp;&nbsp;</span
        ><span>{{ $t("word6") }}</span>
      </div>
      <div class="card-list">
        <div class="card-row">
          <div class="card card1">
            <img src="../assets/icon_1.png" /><span>{{ $t("word7") }}</span>
          </div>
          <div class="card card2">
            <img src="../assets/icon_2.png" /><span>{{ $t("word8") }}</span>
          </div>
          <div class="card card3">
            <img src="../assets/icon_3.png" /><span>{{ $t("word9") }}</span>
          </div>
        </div>
        <div class="card-row">
          <div class="card card4">
            <img src="../assets/icon_4.png" /><span>{{ $t("word10") }}</span>
          </div>
          <div class="card card5">
            <img src="../assets/icon_5.png" /><span>{{ $t("word11") }}</span>
          </div>
          <div class="card card6">
            <img src="../assets/icon_6.png" /><span>{{ $t("word12") }} </span>
          </div>
        </div>
      </div>
    </div>

    <div class="part2" id="discount">
      <img src="../assets/banner2.jpg" />
      <table border="0">
        <tr>
          <th width="287px">{{ $t("word13") }}</th>
          <th width="384px">{{ $t("word14") }}</th>
          <th width="384px">{{ $t("word15") }}</th>
          <th>{{ $t("word16") }}</th>
        </tr>
        <tr>
          <td>{{ $t("word17") }}</td>
          <td>399/{{ $t("word18") }}</td>
          <td>499/{{ $t("word18") }}</td>
          <td>{{ $t("word19") }}</td>
        </tr>
        <tr>
          <td>{{ $t("word20") }}</td>
          <td>{{ $t("word21") }}</td>
          <td>{{ $t("word21") }}</td>
          <td>{{ $t("word21") }}</td>
        </tr>
        <tr>
          <td>{{ $t("word22") }}</td>
          <td>{{ $t("word22_1") }}</td>
          <td>{{ $t("word22_2") }}</td>
          <td>{{ $t("word22_2") }}</td>
        </tr>
        <tr>
          <td>{{ $t("word23") }}</td>
          <td>{{ $t("word24") }}</td>
          <td>{{ $t("word25") }}<br />{{ $t("word25_1") }}</td>
          <td>{{ $t("word25") }}<br />{{ $t("word25_1") }}</td>
        </tr>
        <tr>
          <td>{{ $t("word26") }}</td>
          <td colspan="3">
            {{ $t("word27") }}<br />
            {{ $t("word28") }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("word29") }}</td>
          <td>V</td>
          <td>V</td>
          <td>V</td>
        </tr>
        <tr>
          <td>{{ $t("word30") }}</td>
          <td>V</td>
          <td>V</td>
          <td>V</td>
        </tr>
        <tr>
          <td>{{ $t("word31") }}</td>
          <td>V</td>
          <td>V</td>
          <td>V</td>
        </tr>
      </table>
    </div>

    <div class="part3" id="aboutUs">
      <img
        class="bg"
        src="../assets/about_banner.jpg"
        v-if="$i18n.locale == 'zh-TW'"
      />
      <img
        class="bg"
        src="../assets/about_banner_en.jpg"
        v-if="language == 'en-US'"
      />
      <div class="title">
        <img src="../assets/title_bg.png" />
        <span>{{ $t("freeTest") }}</span>
      </div>
    </div>

    <div class="footer" id="contactUs">
      <span style="font-weight: bold; font-size: 30px"
        >{{ $t("contactUs") }}:</span
      >
      <br /><img src="../assets/contact.png" class="email" />sales@kuaicdn.co
    </div>
    <img src="../assets/logo_11.png" class="copy-right" />

    <div class="fixedWrapper">
      <div class="fixed">
        <span class="logo">KuaiCDN</span>
        <div class="menus">
          <a class="menu" href="#intro">{{ $t("menu1") }}</a>
          <a class="menu" href="#feature">{{ $t("menu2") }}</a>
          <a class="menu" href="#discount">{{ $t("menu3") }}</a>
          <a class="menu" href="#aboutUs">{{ $t("menu4") }}</a>
          <a class="menu" href="#contactUs">{{ $t("menu5") }}</a>
          <a class="menu" @click="changeLanguage()">{{ $t("language") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PcHomePage",
  data() {
    return {
      language: this.$i18n.locale || "en-US",
    };
  },
  mounted() {
    //
  },
  methods: {
    changeLanguage() {
      console.log("changeLanguage");
      if (this.language === "en-US") {
        this.language = "zh-TW";
      } else {
        this.language = "en-US";
      }
      console.log(this.language);
      this.$i18n.locale = this.language;
      window.localStorage.setItem("language", this.language);
    },
  },
};
</script>

<style lang="scss" scoped>
.fixedWrapper {
  align-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 80px;
  justify-content: center;
  left: 0;
  margin: 0 0 0 0;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;

  .logo {
    display: inline-block;
    width: 71px;
    height: 16px;
    font-size: 20px;
    font-weight: 400;
    color: #124bbd;
    margin-top: 26px;
  }

  .fixed {
    margin: 0 auto;
    width: 1446px;

    .menus {
      float: right;
      margin-top: 12px;
      height: 57px;
      opacity: 0.37;
      line-height: 57px;
      padding-left: 16px;

      .menu {
        display: inline-block;
        height: 20px;
        font-size: 20px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #000;
        line-height: 38px;
        margin-right: 16px;
        cursor: pointer;
        // &:hover {
        //   color: #fff;
        // }
      }
    }
  }
}

.homePage {
  a {
    text-decoration: none;
  }
  min-width: 1500px;
  background-color: #f3f3f3;
  position: relative;
  .header {
    position: relative;
    color: #fff;

    .banner {
      width: 100%;
    }

    .left-text {
      position: absolute;
      top: 25%;
      left: 10%;
      font-size: 20px;
      line-height: 30px;

      .line1 {
        .text1 {
          font-size: 40px;
        }
        .text2 {
          font-size: 20px;
        }
      }

      .line2 {
        font-size: 30px;
      }

      .line3 {
        margin: 20px 0;
      }
    }
  }

  .part1 {
    width: 100%;
    height: 30%;

    .title {
      width: 633px;
      height: 58px;
      background: url(../assets/title.png) no-repeat;
      background-size: 100%;
      margin: 84px auto;
      background-position: center;
      line-height: 58px;
      color: #3a4044;
      font-size: 30px;
      text-align: center;
      .text1 {
        color: #2052a6;
      }
    }

    .card-list {
      margin: 0 auto;
      width: 1446px;

      .card-row {
        display: block;
        margin-bottom: 20px;
      }
      .card {
        display: inline-block;
        width: 470px;
        height: 179px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        img {
          margin-left: 35px;
          margin-top: 27px;
        }

        span {
          color: #595757;
          font-size: 25px;
          margin-top: 58px;
          display: inline-block;
          vertical-align: top;
          height: 80px;
          line-height: 40px;
          width: 277px;
          margin-left: 20px;
        }
      }

      .card2,
      .card5 {
        margin: 0 18px;
      }
    }
  }

  .part2 {
    position: relative;

    img {
      width: 100%;
    }

    table {
      position: absolute;
      left: 50%;
      margin-left: -723px;
      width: 1446px;
      top: 40%;
      border-spacing: 0;
      font-size: 20px;

      th {
        background-color: #22272b;
        height: 64px;
        text-align: center;
        line-height: 64px;
        opacity: 0.84;
        color: #fff;
        border: solid 1px rgba(255, 255, 255, 0.4);
      }

      td {
        min-height: 64px;
        line-height: 50px;
        text-align: center;
        color: #181d20;
        border: solid 1px rgba(255, 255, 255, 0.4);
      }

      tr {
        background: #fff;
      }
      tr:nth-of-type(odd) {
        background: #c6cad3;
      }
    }
  }

  .part3 {
    position: relative;
    margin-top: 600px;
    img.bg {
      width: 100%;
    }

    .title {
      position: absolute;
      width: 1446px;
      left: 50%;
      margin-left: -723px;
      top: -83px;

      span {
        width: 516px;
        height: 120px;
        font-size: 32px;
        font-family: DengXian;
        font-weight: 400;
        color: #ffffff;
        line-height: 120px;
        position: absolute;
        left: 0;
        text-align: center;
      }
    }
  }

  .footer {
    width: 1446px;
    height: 70px;
    margin: 0 auto;
    color: #2e3033;
    line-height: 48px;
    padding: 50px 0 200px 0;
    margin-bottom: 0;
    margin-left: 13%;
    img.email {
      width: 30px;
      height: 40px;
      vertical-align: top;
    }
  }

  .copy-right {
    width: 300px;
    margin-left: -150px;
    position: absolute;
    bottom: 50px;
    left: 50%;
  }
}
</style>
