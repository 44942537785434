<template>
  <template v-if="pageType === 'pc'">
    <PcHome></PcHome>
  </template>

  <template v-if="pageType === 'app'">
    <AppHome></AppHome>
  </template>
</template>

<script>
import PcHome from "./PcHome.vue";
import AppHome from "./AppHome.vue";

export default {
  name: "HomePage",
  components: {
    PcHome,
    AppHome,
  },
  data() {
    return {
      pageType: "pc",
    };
  },
  mounted() {
    if (this._isMobile()) {
      this.pageType = "app";
    } else {
      this.pageType = "pc";
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
