<template>
  <div class="homePage">
    <div class="header">
      <span class="logo">KuaiCDN</span>
      <div class="lang" @click="changeLanguage()">
        <span class="en" v-if="language == 'en-US'">{{ $t("language") }}</span>
        <span v-if="language == 'zh-TW'">{{ $t("language") }}</span>
      </div>
    </div>

    <div class="intro" id="intro">
      <img
        class="banner"
        src="../assets/m_banner_02.png"
        v-if="language == 'zh-TW'"
      />
      <img
        class="banner"
        src="../assets/m_banner_02_en.png"
        v-if="language == 'en-US'"
      />
    </div>

    <div class="menus">
      <a class="menu" href="#intro">{{ $t("menu1") }}</a>
      <a class="menu" href="#feature">{{ $t("menu2") }}</a>
      <a class="menu" href="#discount">{{ $t("menu3") }}</a>
      <a class="menu" href="#aboutUs">{{ $t("menu4") }}</a>
      <a class="menu" href="#contactUs">{{ $t("menu5") }}</a>
    </div>

    <div class="part1" id="feature">
      <div class="title">
        <span class="text1">KuaiCDN&nbsp;&nbsp;</span
        ><span>{{ $t("word6") }}</span>
      </div>
      <img src="../assets/m_icon.png" v-if="language == 'zh-TW'" />
      <img src="../assets/m_icon_en.png" v-if="language == 'en-US'" />
    </div>

    <div class="part2" id="discount">
      <img src="../assets/m_banner2.png" v-if="language == 'zh-TW'" />
      <img src="../assets/m_banner2_en.png" v-if="language == 'en-US'" />
    </div>

    <div class="part3" id="aboutUs">
      <img
        class="bg"
        src="../assets/about_banner.jpg"
        v-if="language == 'zh-TW'"
      />
      <img
        class="bg"
        src="../assets/about_banner_en.jpg"
        v-if="language == 'en-US'"
      />
      <div class="title">
        <img src="../assets/title_bg.png" />
        <span v-if="language == 'en-US'" style="top: 0; font-size: 12px">{{
          $t("freeTest")
        }}</span>
        <span v-if="language == 'zh-TW'">{{ $t("freeTest") }}</span>
      </div>
    </div>

    <div class="footer" id="contactUs">
      {{ $t("contactUs") }} : <br /><img
        src="../assets/contact.png"
        class="email"
      />sales@kuaicdn.co
    </div>

    <span class="copy-right">©2022 KuaiCDN TECHNOLOGY SERVICE INC.</span>
  </div>
</template>

<script>
export default {
  name: "PcHomePage",
  data() {
    return {
      language: this.$i18n.locale || "en-US",
    };
  },
  mounted() {
    //
  },
  methods: {
    changeLanguage() {
      console.log("changeLanguage");
      if (this.language === "en-US") {
        this.language = "zh-TW";
      } else {
        this.language = "en-US";
      }
      console.log(this.language);
      this.$i18n.locale = this.language;
      window.localStorage.setItem("language", this.language);
    },
  },
};
</script>

<style lang="scss" scoped>
.homePage {
  position: relative;
  a {
    text-decoration: none;
  }
  background-color: #f3f3f3;

  .header {
    position: relative;
    width: 100%;
    height: 59px;
    .logo {
      position: absolute;
      top: 16px;
      left: 8px;
      display: inline-block;
      width: 71px;
      height: 16px;
      font-size: 20px;
      font-weight: 400;
      color: #124bbd;
    }

    .lang {
      position: absolute;
      top: 16px;
      right: 8px;
      width: 70px;
      height: 27px;
      border: 1px solid #2052a6;
      border-radius: 5px;
      display: inline-block;
      line-height: 27px;
      text-align: center;
      font-size: 11px;
      color: #124cbe;
      span.en {
        transform: scale(0.7);
        font-size: 12px;
        width: 100%;
        height: 100%;
        display: block;
        margin-left: -5px;
      }
    }
  }

  .menus {
    width: 100%;
    height: 32px;
    line-height: 32px;
    background-color: #0f3eb3;
    text-align: center;
    margin-top: -6px;

    .menu {
      display: inline-block;
      height: 11px;
      font-size: 10px;
      margin-right: 16px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #00fdfe;
      line-height: 9px;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }

    .menu:last-child {
      margin-right: 0;
    }
  }
  .intro {
    position: relative;
    color: #fff;

    .banner {
      width: 100%;
    }

    .left-text {
      position: absolute;
      top: 25%;
      left: 10%;
      font-size: 20px;
      line-height: 30px;

      .line1 {
        .text1 {
          font-size: 40px;
        }
        .text2 {
          font-size: 20px;
        }
      }

      .line2 {
        font-size: 30px;
      }

      .line3 {
        margin: 20px 0;
      }
    }
  }

  .part1 {
    width: 100%;

    .title {
      width: 100%;
      height: 16px;
      background: url(../assets/title.png) no-repeat;
      background-size: 100%;
      margin: 18px auto;
      background-position: center;
      line-height: 16px;
      color: #3a4044;
      font-size: 16px;
      text-align: center;

      .text1 {
        color: #2052a6;
      }
    }

    img {
      width: 100%;
    }
  }

  .part2 {
    position: relative;

    img {
      width: 100%;
    }
  }

  .part3 {
    position: relative;
    margin-top: 50px;
    img.bg {
      width: 100%;
    }

    .title {
      position: absolute;
      top: -22px;
      left: 20px;

      img {
        height: 32px;
      }

      span {
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        top: 5px;
        color: #fff;
      }
    }
  }

  .footer {
    width: 100%;
    height: 30px;
    margin: 0 auto;
    color: #2e3033;
    line-height: 20px;
    padding: 30px 0 56px 11px;
    margin-bottom: 0;
    margin-left: 10%;
    img.email {
      width: 13px;
      height: 18px;
      vertical-align: top;
    }
  }

  .copy-right {
    width: 256px;
    margin-left: -128px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    font-size: 12px;
    transform: scale(0.8);
  }
}
</style>
