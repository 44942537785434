export default {
  menu1: "Introduction",
  menu2: "Features",
  menu3: "Offers",
  menu4: "About us",
  menu5: "Contact us",
  language: "中文",
  word1: "One-stop multiple CDN platform",
  word2: "Improve your website service performance",
  word3: "Integrate various CDN providers in a converged system. ",
  word4: "When the CDN node of provider A crashes, it will automatically",
  word5:
    "transfer to the pre-set route of provider B, so that the fast information ",
  word5_1: "transfer service will not be interrupted.",
  word6: "features",
  word7: "One-stop management platform",
  word8: "The operation interface is simple and easy to understand",
  word9: "Avoid service barriers in a single CDN",
  word10: "7x24 website performance monitoring and protection",
  word11: "Easy to deploy and easy to manage",
  word12: "Resist DDoS, CC attack",
  word13: "platform licensing",
  word14: "Basic",
  word15: "PRO",
  word16: "Customized version",
  word17: "Currency: USD",
  word18: "month",
  word19: "Customization",
  word20: "content",
  word21: "primary domain name analysis",
  word22: "TTL low",
  word22_1: "Minimum TTL of 60s",
  word22_2: "Minimum TTL of 1s",
  word23: "Intelligent line support",
  word24: "Split zones by  country, continent and search engine  are supported",
  word25: "Split zones by country, continent,search engine,carrier",
  word25_1:
    "inside or  outside the Chinese mainland,and  China region are supported",
  word26: "The CDN industry is available",
  word27: "1.  In  China:Tencent  ,Ali,Huawei,Baidu,Wangsu.",
  word28: "2.  Overseas: Cloudflare、Akamai、Conversant、AWS.",
  word29: "Website health monitoring",
  word30: "SSL Certificate Management",
  word31: "Cache refresh",
  contactUs: "Contact us",
  freeTest: "Get 3 Months free",
};
