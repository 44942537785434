// language文件夹下的index.js
// zh, en, id 分别为三种语言文件
import { createI18n } from "vue-i18n";
import tw from "./tw";
import en from "./en";

const languages = ["en-US", "zh-TW"];
let defaultLang = navigator.language || navigator.userLanguage;
if (languages.indexOf(defaultLang) < 0) {
  defaultLang = "en-US";
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: localStorage.getItem("language") || defaultLang,
  messages: {
    "en-US": en,
    "zh-TW": tw,
  },
});

export default i18n;
