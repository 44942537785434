export default {
  menu1: "KuaiCDN簡介",
  menu2: "KuaiCDN特色",
  menu3: "優惠方案",
  menu4: "關於我們",
  menu5: "聯絡我們",
  language: "English",
  word1: "一站式多重CDN平台",
  word2: "提升您的網站服務效能",
  word3: "整合各家CDN供應商在一個融合系統,",
  word4: "當A供應商的CDN節點當機時,將自動轉移到預先設",
  word5: "定好的B供應商路線, 讓資訊快速轉移服務不中斷",
  word5_1: "",
  word6: "特色",
  word7: "一站式管理平台",
  word8: "操作介面簡潔易懂",
  word9: "避免單一CDN發生服務障礙",
  word10: "7x24 全天候網站效能監控和保護",
  word11: "易於佈署,管理輕鬆",
  word12: "抵擋DDoS,CC攻擊",
  word13: "平台使用費",
  word14: "基本版",
  word15: "專業版",
  word16: "訂製版",
  word17: "幣別：USD",
  word18: "月",
  word19: "專案訂製",
  word20: "内容",
  word21: "别名解析，泛解析 1個主域名",
  word22: "TTL最低值",
  word22_1: "60s TTL最低值",
  word22_2: "1s TTL最低值",
  word23: "智能線路支持",
  word24: "國家，大洲，搜索引擎智能線路",
  word25: "國家，大洲，搜索引擎，運营商 ",
  word25_1: "中國境内外，中國大區等智能線路",
  word26: "可使用CDN業者",
  word27: "1.中國境内：騰訊、阿里、華為、百度、網宿.",
  word28: "2.境外：Cloudflare、Akamai、Conversant、AWS.",
  word29: "網站健康監控",
  word30: "SSL證書管理",
  word31: "緩存刷新",
  contactUs: "聯絡我們",
  freeTest: "三個月免費測試",
};
